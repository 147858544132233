import { ContemberClientProps } from '@contember/react-client'

export const entrypointConfig = (() => {

	let project = import.meta.env.VITE_CONTEMBER_ADMIN_PROJECT_NAME

	if (project === '__PROJECT_SLUG__') {
		project = window.location.pathname.split('/')[1]
	}

	const sessionToken = import.meta.env.VITE_CONTEMBER_PUBLIC_SESSION_TOKEN as string
	if (!sessionToken) {
		throw new Error('VITE_CONTEMBER_PUBLIC_SESSION_TOKEN is not set')
	}

	const apiBaseUrl = import.meta.env.VITE_CONTEMBER_ADMIN_API_BASE_URL
	const loginToken = import.meta.env.VITE_CONTEMBER_ADMIN_LOGIN_TOKEN
	if (!apiBaseUrl) {
		throw new Error('VITE_CONTEMBER_ADMIN_API_BASE_URL is not set')
	}
	if (!loginToken) {
		throw new Error('VITE_CONTEMBER_ADMIN_LOGIN_TOKEN is not set')
	}
	return {
		clientConfig: {
			project,
			sessionToken,
			stage: 'live',
			apiBaseUrl: apiBaseUrl,
			loginToken: loginToken,
		} satisfies ContemberClientProps,
		appUrl: '/app',
		hasTokenFromEnv: import.meta.env.VITE_CONTEMBER_ADMIN_SESSION_TOKEN !== '__SESSION_TOKEN__',
		idps: {
			// You can add more IDPs here
			// 	google: 'Login with Google',
		} satisfies Record<string, string>,
		magicLink: false,
	}
})()

