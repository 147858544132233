import { FunctionComponent, ReactNode } from "react"
import { FormLabelUI } from "~/lib/form"
import { RadioInput } from "~/lib/ui/input"

type QuestionRadioProps = {
	id: string
	label: ReactNode
	options: { value: string, label: ReactNode }[]
	required?: boolean
	description?: ReactNode
	selectedOption?: string
	onChange: (checked: boolean, value: string) => void
}

export const QuestionRadio: FunctionComponent<QuestionRadioProps> = ({ id, label, options, required, selectedOption, onChange }) => {
	return (
		<>
			<FormLabelUI className="block font-semibold mb-2 text-base">{label}</FormLabelUI>
			<div className={'flex flex-wrap gap-3 data-[orientation=vertical]:flex-col'} data-orientation={'vertical'}>
				{options.map(({ value, label }) => (
					<FormLabelUI className="flex gap-2 items-center font-medium text-base" key={value?.toString()}>
						<RadioInput
							type="radio"
							value={value}
							required={required}
							name={id}
							checked={selectedOption === value}
							onChange={e => onChange(e.target.checked, value)}
						/>
						{label}
					</FormLabelUI>
				))}
			</div>
		</>)
}