import { FunctionComponent, ReactNode } from "react"
import { FormLabelUI } from "~/lib/form"
import { CheckboxInput } from "~/lib/ui/input"

type QuestionCheckboxProps = {
	id: string
	label: ReactNode
	options: { value: string, label: ReactNode }[]
	required?: boolean
	description?: ReactNode
	selectedOptions?: string[]
	onChange: (checked: boolean, value: string) => void
}

export const QuestionCheckbox: FunctionComponent<QuestionCheckboxProps> = ({ id, label, options, required, selectedOptions, onChange }) => {
	return (
		<>
			<FormLabelUI className="block font-semibold mb-2 text-base">{label}</FormLabelUI>
			<div className={'flex flex-wrap gap-3 data-[orientation=vertical]:flex-col'} data-orientation={'vertical'}>
				{options.map(({ value, label }) => (
					<FormLabelUI className="flex gap-2 items-center font-normal text-base" key={value?.toString()}>
						<CheckboxInput
							type="checkbox"
							value={value}
							required={required}
							name={`${id}_${value}`}
							checked={selectedOptions?.includes(value)}
							onChange={e => onChange(e.target.checked, value)}
						/>
						{label}
					</FormLabelUI>
				))}
			</div>
		</>)
}