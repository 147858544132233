
import { type ReactNode } from 'react'
import { renderElement, renderLeaf } from './renderers'
import type { LeafRenderer, RichTextElement } from './types'

export type RenderRichTextProps = {
	renderLeaf: LeafRenderer
	renderElement: (element: RichTextElement, leafRenderer: LeafRenderer) => ReactNode
}

export const renderRichText = (
	// biome-ignore lint/complexity/noBannedTypes: <explanation>
	value: Object | string,
	references?: any,
) => {
	let parsed: any
	try {
		parsed = typeof value === 'string' ? JSON.parse(value) : value
	} catch (_e) {
		return value
	}

	if (value && Array.isArray(parsed.children)) {
		return parsed.children.map((element: RichTextElement) => renderElement(element, renderLeaf, references))
	}

	if (typeof parsed === 'number') {
		return value
	}

	return null
}

export const RichTextRenderer = ({
	value,
}: {
	value: any
}) => {
	return renderRichText(value)
}

