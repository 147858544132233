import React, { createElement, Fragment, type ReactNode } from 'react'
import { isLeaf, type LeafRenderer, type RichTextElement } from './types'

export const renderLeaf: LeafRenderer = (leaf: any) => {
	let content = (
		<>
			{leaf.text.split('\n').map((part: string, index: number) => (
				<React.Fragment key={index}>
					{!!index && <br />}
					{part}
				</React.Fragment>
			))}
		</>
	)

	if (leaf.isBold) {
		content = <strong>{content}</strong>
	}
	if (leaf.isItalic) {
		content = <em>{content}</em>
	}
	if (leaf.isUnderline) {
		content = <u>{content}</u>
	}
	if (leaf.isStrikethrough) {
		content = <s>{content}</s>
	}
	if (leaf.isCode) {
		content = <code>{content}</code>
	}

	return content
}

export type RenderElement = (
	element: RichTextElement,
	renderLeaf: LeafRenderer,
	references?: any,
) => ReactNode

export const renderElement: RenderElement = (element, renderLeaf, references) => {
	if (isLeaf(element)) {
		return <>{renderLeaf(element)}</>
	}

	const children = element.children.map((child, index) => {
		if (isLeaf(child)) {
			return <Fragment key={index}>{renderLeaf(child)}</Fragment>
		}

		return <Fragment key={index}>{renderElement(child, renderLeaf)}</Fragment>
	})

	const block = null //TODO: implement this
	//const block = element.referenceId && references?.find((ref) => ref.id === element.referenceId)?.block

	switch (element.type) {
		case 'paragraph':
		case 'p':
			return <p>{children}</p>
		case 'heading': {
			const elementType = `h${element.level ?? 3}`
			return createElement(elementType, {}, children)
		}
		case 'h1':
		case 'h2':
		case 'h3':
		case 'h4':
		case 'h5':
		case 'h6':
			return createElement(element.type, {}, children)
		case 'extraHeading':
			return createElement('h6', { class: 'h7' }, children)
		case 'a':
			return (
				<a href={element.url} target={'_blank'} rel="noreferrer">
					{children}
				</a>
			)
		case 'anchor':
			return (
				<a href={element.href}>
					{children}
				</a>
			)
		case "unorderedList":
			return <ul className="renderUl">{children}</ul>
		case "orderedList":
			return <ol className="renderOl">{children}</ol>
		case "listItem":
			return <li>{children}</li>
		/*
	case 'image': {
		if (!block) {
			return null
		}

		const image = block.image

		if (!image) {
			return null
		}
		return <img src={image.url} alt={image.alt ?? ''} />
	}
		*/
		default:
			return <div>{children}</div>
	}
}
